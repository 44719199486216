<template>
  <div class="wrap">
    <h1 class="article-title">{{ info.title || "今日内容" }}</h1>
    <div class="title" v-html="info.content || '暂无信息'"></div>
    <div class="event_wrap" v-if="!show">
      <div class="event" @click="getUrl">点击解锁内容</div>
    </div>
    <div class="container" v-else>
      <a class="url-link" v-html="info.url"></a>
    </div>
    <div class="instruction" v-if="!show">
      <h2 class="instruction-title">观看视频了解如何操作：</h2>
      <div class="instruction-video">
        <iframe :src="info.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrap {
  font-family: 'Georgia', serif;
  color: #333;
  background: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 100%);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
}

.article-title {
  font-size: 32px;
  margin: 0 0 20px 0;
  text-align: center;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-weight: bold;
}

.title {
  font-size: 20px;
  line-height: 1.6;
  margin: 0 0 30px 0;
  color: #666;
  text-align: justify;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.event_wrap {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.event {
  color: #fff;
  background: linear-gradient(to right, #ff5f6d, #ffc371);
  padding: 15px 20px;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.container {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.url-link {
  color: #56ccf2;
  font-size: 28px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.url-link:hover {
  color: #ff6e7f;
}

.instruction {
  margin-top: 20px;
  background: #e9ecef;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.instruction-title {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.instruction-video {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
}

.instruction-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<script>
import axios from "axios"
export default {
  data() {
    return {
      id: null, // 添加 id 属性
      str: null,
      show: false,
      info: {
        title: null, // 确保 info 对象包含 title 属性
        content: null,
        url: null,
        video: null
      }
    };
  },
  created() {
    // 从路由参数中获取 id
    const id = this.$route.query.id;
    if (id) {
      this.id = id;
      this.fetchData(id);
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    async fetchData(id) {
      try {
        // 使用传入的 id 参数构建 URL
        const response = await axios.get(`https://fx.x5j.xyz/app/xsbz.php?id=${id}`);
        // 假设响应的数据结构包含 title, content 和 url
        if(response.data.code==201){
           this.show = true
        }
        this.info.title = response.data.title;
        this.info.content = response.data.content;
        this.info.url = response.data.url;
        this.info.video = response.data.video;
      } catch (error) {
        console.error("请求失败:", error);
      }
    },
    async handleVisibilityChange() {
      if (document.visibilityState === 'visible' && this.str) {
        let result = await this.showState(this.str)
        if (result.code !== 1) {
          this.show = true
          this.str = null
        }
      }
    },
    async getUrl() {
      let result = await axios.get("https://fx.x5j.xyz/api/url")
      let { time, url } = result.data
      this.str = time
      window.location.href = url


    },
    async showState(str) {
      return new Promise(async (resovle, reject) => {
        let result = await axios.get("https://fx.x5j.xyz/api/state/" + str)
        resovle(result.data)
      })

    }
  }
}
</script>
